import type { NavigationItem } from '~/types/NavigationItem'

export const useNavigationItemStore = defineStore('navigationItemStore', {
  state: (): {
    navItems: NavigationItem[]
  } => ({
    navItems: []
  }),

  getters: {
    items: (state) => state.navItems
  },
  actions: {
    setItems(navItems: NavigationItem[]) {
      this.navItems = navItems
    }
  }
})
